// src/components/ServiceItem.js
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { MdLocationOn } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

const ServiceItem = ({ service, id, onEdit, onDelete }) => {
  return (
    <li className="relative bg-white flex flex-col justify-between items-center shadow-md hover:shadow-xl rounded-md overflow-hidden transition-shadow duration-150 m-[10px]">
      <div className="contents">
        <img
          className="h-[170px] w-full object-cover hover:scale-110 transition-scale duration-200 ease-in"
          loading="lazy"
          src={service.imgUrl}
          alt=""
        />
        <Moment
          className="absolute top-2 left-2 bg-blue-400 text-white uppercase text-xs font-semibold rounded-md px-2 py-1 shadow-lg"
          fromNow
        >
          {service.timestamp?.toDate()}
        </Moment>
        <div className="w-full p-[10px]">
          <p className="font-semibold m-0 text-xl truncate">{service.name}</p>
          <p className="text-blue-400 mt-2 font-semibold">
            ₦
            {service.price
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <div className="flex items-center mt-[10px] space-x-3">
            <div className="flex items-center space-x-1">
              <p className="font-bold text-xs">
                {service.description}
              </p>
            </div>
          </div>
        </div>
      </div>
      {onDelete && (
        <FaTrash
          className="absolute bottom-2 right-2 h-[14px] cursor-pointer text-red-500"
          onClick={() => onDelete(service.id)}
        />
      )}
      {/* {onEdit && (
        <MdEdit
          className="absolute bottom-2 right-7 h-4 cursor-pointer"
          onClick={() => onEdit(service.id)}
        />
      )} */}
    </li>
  );
};

export default ServiceItem;
