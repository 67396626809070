import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from "../firebase";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";

const Advertisement = () => {
    const [ads, setAds] = useState([]);
    const [image, setImage] = useState(null);
    const [content, setContent] = useState('');
    const [link, setLink] = useState('');
    const [adType, setAdType] = useState('image'); // 'image' or 'text'
    const [editingAdId, setEditingAdId] = useState(null);

    const storage = getStorage();

    useEffect(() => {
        const fetchAds = async () => {
            try {
                const adsCollection = collection(db, 'ads');
                const adsSnapshot = await getDocs(adsCollection);
                const adsData = adsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAds(adsData);
            } catch (error) {
                console.error('Error fetching ads:', error);
                toast.error('Error fetching ads!');
            }
        };

        fetchAds();
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleUpload = async () => {
        const storageRef = ref(storage, image.name);
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Handle progress
            },
            (error) => {
                console.error('Error uploading image:', error);
                toast.error('Error uploading image!');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    setImage(downloadURL);
                    const newAd = {
                        imageUrl: downloadURL,
                        content,
                        link,
                        adType,
                        createdAt: new Date(),
                    };

                    if (editingAdId) {
                        await updateDoc(doc(db, 'ads', editingAdId), newAd);
                        toast.success('Advertisement updated successfully!');
                    } else {
                        await addDoc(collection(db, 'ads'), newAd);
                        toast.success('Advertisement added successfully!');
                    }

                    setImage(null);
                    setContent('');
                    setLink('');
                    setAdType('image');
                    setEditingAdId(null);
                });
            }
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (adType === 'image' && image) {
            handleUpload();
        } else {
            const newAd = {
                content,
                link,
                adType,
                createdAt: new Date(),
            };

            if (editingAdId) {
                updateAd(editingAdId, newAd);
            } else {
                addNewAd(newAd);
            }

            setContent('');
            setLink('');
            setAdType('image');
            setEditingAdId(null);
        }
    };

    const addNewAd = async (newAd) => {
        try {
            await addDoc(collection(db, 'ads'), newAd);
            toast.success('Advertisement added successfully!');
        } catch (error) {
            console.error('Error adding ad:', error);
            toast.error('Error adding ad!');
        }
    };

    const updateAd = async (id, newAd) => {
        try {
            await updateDoc(doc(db, 'ads', id), newAd);
            toast.success('Advertisement updated successfully!');
        } catch (error) {
            console.error('Error updating ad:', error);
            toast.error('Error updating ad!');
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'ads', id));
            setAds((prevAds) => prevAds.filter((ad) => ad.id !== id));
            toast.success('Advertisement deleted successfully!');
        } catch (error) {
            console.error('Error deleting ad:', error);
            toast.error('Error deleting ad!');
        }
    };

    const handleEdit = (ad) => {
        setContent(ad.content);
        setLink(ad.link);
        setAdType(ad.adType);
        setEditingAdId(ad.id);
    };

    return (
        <div className="advertisement-admin">

            <form onSubmit={handleSubmit} className="flex justify-center flex-col align-center max-w-[1200px] mx-auto items-center">
                <div className="input-group w-[400px]">
                    <label>Ad Type:</label>
                    <select value={adType} onChange={(e) => setAdType(e.target.value)} className='w-full bg-blue-400 text-white'>
                        <option value="image">Image</option>
                        <option value="text">Text</option>
                    </select>
                </div>

                {adType === 'image' && (
                    <>
                        <div className="flex justify-center flex-col align-center w-[400px] mx-auto items-center">
                            <label>Upload Image:</label>
                            <input type="file" onChange={handleImageChange} className='w-full border rounded py-1 px-3' />
                            {image && <button onClick={handleUpload}>Upload</button>}
                        </div>
                        <div className="flex justify-center flex-col align-center w-[400px] mx-auto items-center">
                            <label>Link:</label>
                            <input
                                type="text"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                required
                                className="w-full rounded"
                            />
                        </div>
                    </>
                )}

                {adType === 'text' && (
                    <>
                        <div className="flex justify-center flex-col align-center w-[400px] mx-auto items-center">
                            <label>Content:</label>
                            <textarea
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                required
                                className="w-full"
                            ></textarea>
                        </div>
                        <div className="flex justify-center flex-col align-center w-[400px] mx-auto items-center">
                            <label>Link:</label>
                            <input
                                type="text"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                required
                                className="w-full"
                            />
                        </div>
                    </>
                )}

                <button type="submit" className="bg-blue-400 py-2 px-5 rounded mt-3 text-white">{editingAdId ? 'Update Ad' : 'Add Ad'}</button>
            </form>

            <ul className="ads-list">
                {ads.map((ad) => (
                    <li key={ad.id} className="ad-item">
                        {ad.adType === 'image' ? (
                            <a href={ad.link} target="_blank" rel="noopener noreferrer" className="max-w-200px">
                                <img src={ad.imageUrl} alt="Advertisement" className="w-[200px]" />
                            </a>
                        ) : (
                            <div>
                                <p>{ad.content}</p>
                                <a href={ad.link} target="_blank" rel="noopener noreferrer">
                                    Learn More
                                </a>
                            </div>
                        )}

                        <div className="ad-actions">
                            <button onClick={() => handleEdit(ad)}>Edit</button>
                            <button onClick={() => handleDelete(ad.id)}>Delete</button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Advertisement;
