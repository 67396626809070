import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { getStorage, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { collection, addDoc, getDocs, query, where, updateDoc, doc, deleteDoc, getDoc, runTransaction } from "firebase/firestore";
import { toast } from "react-toastify";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import Advertisement from "../components/Advertisement";
import ServiceManagement from "../components/ServiceManagement";
import CreateBlog from "../components/CreateBlog";

const MAX_ADMINS = 3;
const Admin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [users, setUsers] = useState([]);
    const [listings, setListings] = useState([]);
    const [pendingListings, setPendingListings] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [activeTab, setActiveTab] = useState("users");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationTitle, setNotificationTitle] = useState("");
    const [image, setImage] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupEmail, setPopupEmail] = useState("");
    const [popupMessage, setPopupMessage] = useState("");
    const [currentListingId, setCurrentListingId] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [currentListingName, setCurrentListingName] = useState("");
    const storage = getStorage();

    useEffect(() => {
        // const checkAdmin = async () => {
        //     try {
        //         const adminQuery = query(collection(db, "admins"), where("email", "==", email));
        //         const adminSnapshot = await getDocs(adminQuery);
        //         if (!adminSnapshot.empty) {
        //             setIsAdmin(true);
        //         }
        //     } catch (error) {
        //         console.error("Error checking admin:", error);
        //     }
        // };

        const checkAdmin = async (email) => {
            try {
                const adminQuery = query(collection(db, "admins"), where("email", "==", email));
                const adminSnapshot = await getDocs(adminQuery);
                if (!adminSnapshot.empty) {
                    setIsAdmin(true);
                    return true;
                }
                return false;
            } catch (error) {
                console.error("Error checking admin:", error);
                return false;
            }
        };
        const addAdmin = async (newAdminData) => {
            try {
                await runTransaction(db, async (transaction) => {
                    const adminsCollectionRef = collection(db, "admins");
                    const adminsSnapshot = await transaction.get(adminsCollectionRef);
                    const adminCount = adminsSnapshot.size;

                    if (adminCount >= MAX_ADMINS) {
                        throw new Error("Maximum number of admin documents reached");
                    }

                    const newAdminDocRef = doc(adminsCollectionRef);
                    transaction.set(newAdminDocRef, newAdminData);
                });

                toast.success("Admin added successfully");
            } catch (error) {
                console.error("Error adding admin:", error);
                if (error.message === "Maximum number of admin documents reached") {
                    toast.error("Maximum number of admin documents reached");
                } else {
                    toast.error("Error adding admin");
                }
            }
        };

        const fetchUsers = async () => {
            try {
                const usersCollection = collection(db, "users");
                const usersSnapshot = await getDocs(usersCollection);
                const usersData = usersSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setUsers(usersData);
            } catch (error) {
                console.error("Error fetching users:", error);
                toast.error("Error fetching users!");
            }
        };

        const fetchListings = async () => {
            try {
                const listingsCollection = collection(db, "listings");
                const listingsSnapshot = await getDocs(listingsCollection);
                const listingsData = listingsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setListings(listingsData);
            } catch (error) {
                console.error("Error fetching listings:", error);
                toast.error("Error fetching listings!");
            }
        };

        const fetchPendingListings = async () => {
            try {
                const pendingListingsCollection = collection(db, "pending");
                const pendingListingsSnapshot = await getDocs(pendingListingsCollection);
                const pendingListingsData = pendingListingsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setPendingListings(pendingListingsData);
            } catch (error) {
                console.error("Error fetching pending listings:", error);
                toast.error("Error fetching pending listings!");
            }
        };

        checkAdmin(email);
        fetchUsers();
        fetchListings();
        fetchPendingListings();
    }, [email]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const adminQuery = query(collection(db, "admins"), where("email", "==", email));
            const adminSnapshot = await getDocs(adminQuery);
            if (adminSnapshot.empty) {
                const adminsCollectionRef = collection(db, "admins");
                const adminsSnapshot = await getDocs(adminsCollectionRef);
                if (adminsSnapshot.size >= MAX_ADMINS) {
                    toast.error("Maximum number of admin documents reached");
                    return;
                }

                await addDoc(adminsCollectionRef, {
                    email,
                    password,
                });
                toast.success("Admin account created successfully!");
            } else {
                await signInWithEmailAndPassword(getAuth(), email, password);
                toast.success("Logged in successfully!");
                setIsAdmin(true);
            }
        } catch (error) {
            console.error("Login error:", error);
            toast.error("Error logging in!");
        }
    };

    // const deleteItem = async (collectionName, id) => {
    //     try {
    //         await deleteDoc(doc(db, collectionName, id));
    //         if (collectionName === "users") {
    //             setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
    //         } else if (collectionName === "listings") {
    //             setListings((prevListings) => prevListings.filter((listing) => listing.id !== id));
    //         }
    //         toast.success("Item deleted successfully!");
    //     } catch (error) {
    //         console.error("Error deleting item:", error);
    //         toast.error("Error deleting item!");
    //     }
    // };

    const deleteItem = async (collectionName, id) => {
        try {
            // Attempt to delete the document from the specified collection
            const docRef = doc(db, collectionName, id);
            await deleteDoc(docRef);
    
            // Check which collection was affected and update the corresponding state
            if (collectionName === "users") {
                setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
            } else if (collectionName === "listings") {
                setListings((prevListings) => prevListings.filter((listing) => listing.id !== id));
            }
    
            // Notify the user of successful deletion
            toast.success("Item deleted successfully!");
        } catch (error) {
            // Log the error and notify the user of failure
            console.error("Error deleting item:", error);
            toast.error("Error deleting item!");
        }
    };

    const handleNotificationSubmit = async (e) => {
        e.preventDefault();

        try {
            let imageUrl = "";
            if (image) {
                const storageRef = storage.ref(`notifications/${image.name}`);
                await uploadBytesResumable(storageRef, image);
                imageUrl = await getDownloadURL(storageRef);
            }

            const docRef = await addDoc(collection(db, 'notifications'), {
                title: notificationTitle,
                message: notificationMessage,
                imageUrl: imageUrl,
                timestamp: new Date(),
                read: false,
            });
            toast.success('Notification sent successfully!');
            setNotificationMessage('');
            setImage(null);
        } catch (error) {
            console.error('Error sending notification:', error);
            toast.error('Error sending notification!');
        }
    };

    const sendNotification = async (userId, message) => {
        try {
            await addDoc(collection(db, 'notifications'), {
                userId,
                message,
                timestamp: new Date(),
                read: false,
            });
            toast.success('Notification sent to user!');
        } catch (error) {
            console.error('Error sending notification to user:', error);
            toast.error('Error sending notification to user!');
        }
    };

    const handleApproveReject = async (pendingId, userId, email, pendingName, isApproved) => {
        try {
            const status = isApproved ? "approved" : "rejected";
            await updateDoc(doc(db, "pending", pendingId), { status });
            await deleteDoc(doc(db, "pending", pendingId));
            setPendingListings((prevListings) => prevListings.filter((pending) => pending.id !== pendingId));

            if (isApproved) {
                const listingData = pendingListings.find(pending => pending.id === pendingId);
                await addDoc(collection(db, "listings"), { ...listingData, status });
            }

            window.location.href = `mailto:${email}?Subject=Your listing has been ${status}&body=Your listing "${pendingName}" has been ${status}.`;

            toast.success(`Listing ${status} successfully!`);
        } catch (error) {
            console.error(`Failed to ${isApproved ? 'approve' : 'reject'} listing`, error);
            toast.error(`Failed to ${isApproved ? 'approve' : 'reject'} listing`);
        }
    };

    const approveListing = async () => {
        try {
          await updateDoc(doc(db, "pending", currentListingId), { status: "approved" });
          await deleteDoc(doc(db, "pending", currentListingId));
          setPendingListings((prevListings) => prevListings.filter((listing) => (listing.id) !== currentListingId));
          const listingData = pendingListings.find(listing => (listing.id) === currentListingId);
          await addDoc(collection(db, "listings"), { ...listingData, status: "approved" });
          // Add document to "listings2" collection with only the "name" field
          await addDoc(collection(db, "listings2"), { name: listingData.name, type: listingData.type });
          sendNotification(currentUserId, popupMessage);
          // Fetch user data to send email
          const userDoc = await getDoc(doc(db, "users", currentUserId));
          if (userDoc.exists()) {
            const user = userDoc.data();
            window.location.href = `mailto:${user.email}?Subject=Your listing has been approved&body=${popupMessage}`;
          }
          setPopupVisible(false);
        } catch (error) {
          console.error("Failed to approve listing", error);
          toast.error("Failed to approve listing");
        }
      };
    
    

    const rejectListing = async () => {
        try {
            await updateDoc(doc(db, "pending", currentListingId), { status: "rejected" });
            setPendingListings((prevListings) => prevListings.filter((listing) => listing.id !== currentListingId));
            sendNotification(currentUserId, popupMessage);
            // Fetch user data to send email
            const userDoc = await getDoc(doc(db, "users", currentUserId));
            if (userDoc.exists()) {
                const user = userDoc.data();
                window.location.href = `mailto:${user.email}?Subject=Your listing has been rejected&body=${popupMessage}`;
            }
            setPopupVisible(false);
        } catch (error) {
            console.error("Failed to reject listing", error);
            toast.error("Failed to reject listing");
        }
    };

    return (
        <div className="admin-page">
            <h1 className="text-center font-bold text-4xl my-5">Admin Page</h1>
            {!isAdmin && (
                <form onSubmit={handleLogin} className="flex flex-col md-down:mx-3 max-w-[768px] mx-auto gap-5">
                    <div className="flex flex-col">
                        <label htmlFor="email" className="mb-1">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="border p-2 rounded"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="password" className="mb-1">Password:</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="border p-2 rounded"
                        />
                    </div>
                    <button type="submit" className="bg-blue-400 text-white py-2 px-4 rounded mt-4">Login</button>
                </form>
            )}

            {isAdmin && (
                <>
                    <div className="flex flex-wrap justify-center space-x-4 mb-5">
                        <button
                            onClick={() => setActiveTab("users")}
                            className={`py-2 mb-5 px-5 text-white ${activeTab === "users" ? "bg-[#F6D200]" : "bg-blue-400"}`}
                        >
                            Users
                        </button>
                        {/* <button
                            onClick={() => setActiveTab("listings")}
                            className={`py-2 mb-5 px-5 text-white ${activeTab === "listings" ? "bg-[#F6D200]" : "bg-blue-400"}`}
                        >
                            Listings
                        </button> */}
                        <button
                            onClick={() => setActiveTab("pendingListings")}
                            className={`py-2 mb-5 px-5 text-white ${activeTab === "pendingListings" ? "bg-[#F6D200]" : "bg-blue-400"}`}
                        >
                            Pending Listings
                        </button>
                        {/* <button
                            onClick={() => setActiveTab("notifications")}
                            className={`py-2 mb-5 px-5 text-white ${activeTab === "notifications" ? "bg-[#F6D200]" : "bg-blue-400"}`}
                        >
                            Notifications
                        </button>
                        <button
                            onClick={() => setActiveTab("ads")}
                            className={`py-2 mb-5 px-5 text-white ${activeTab === "ads" ? "bg-[#F6D200]" : "bg-blue-400"}`}
                        >
                            Advertisement
                        </button> */}
                        <button
                            onClick={() => setActiveTab("services")}
                            className={`py-2 mb-5 px-4 text-white ${activeTab === "services" ? "bg-[#F6D200]" : "bg-blue-400"}`}
                        >
                            Services
                        </button>
                        <button
                            onClick={() => setActiveTab("blogs")}
                            className={`py-2 mb-5 px-4 text-white ${activeTab === "blogs" ? "bg-[#F6D200]" : "bg-blue-400"}`}
                        >
                            Write Blog
                        </button>
                    </div>

                    {activeTab === "users" && (
                        <div className="overlay">
                            <h2 className="text-center font-semibold text-xl mb-5">Users</h2>
                            <ul className="flex flex-wrap max-w-[1200px] mx-auto gap-3 justify-center">
                                {users.map((user) => (
                                    <div key={user.id} className="flex justify-between items-center bg-blue-400 text-white py-3 px-7 w-[350px]">
                                        <Link to={`/profile/${user.name}`} className="flex items-center">
                                            <img src={user.photoURL} alt={user.name} className="w-12 h-12 rounded-full mr-2" />
                                            <span>{user.name} - {user.email.slice(0, 10)}...</span>
                                        </Link>
                                        <button onClick={() => deleteItem('users', user.id)} className="bg-[#F6D200] py-1 px-3">Delete</button>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    )}

                    {activeTab === "listings" && (
                        <div className="overlay">
                            <h2 className="text-center font-semibold text-xl mb-5">Listings</h2>
                            <ul className="flex flex-wrap max-w-[1200px] justify-center mx-auto gap-1">
                                {listings.map((listing) => (
                                    <li key={listing.id} className="flex justify-between items-center bg-blue-400 text-white py-3 px-7 w-[250px]">
                                        <Link to={`/listing/${listing.id}`} className="text-white">
                                            {listing.name.slice(0, 10)}...
                                        </Link>
                                        <button onClick={() => deleteItem('listings', listing.id)} className="bg-[#F6D200] py-1 px-3">Delete</button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {activeTab === "pendingListings" && (
                        <div className="overlay">
                            <h2 className="text-center font-semibold text-xl mb-5">Pending Listings</h2>
                            <ul className="flex flex-wrap max-w-[1200px] justify-center mx-auto gap-1">
                                {pendingListings.map((pending) => (
                                    <li key={pending.id} className="flex flex-wrap justify-between items-center bg-blue-400 text-white py-3 px-7 w-[280px]">
                                        <Link to={`/pending/${pending.id}`} className="text-white">
                                            {pending.name.slice(0, 15)}...
                                        </Link>
                                        <a href={`mailto:${pending.email}?Subject=${pending.name}&body=${""}`}><p>{pending.email}</p></a>
                                        <p>{pending.selectedService.name}</p>
                                        <p>₦ {pending.selectedService.price}</p>
                                        <div className='flex flex-wrap justify-between'>
                                            <button onClick={() => handleApproveReject(pending.id, pending.userId, pending.email, pending.name, true)} className="bg-green-500 py-1 px-3 w-[48%]">Approve</button>
                                            <button onClick={() => handleApproveReject(pending.id, pending.userId, pending.email, pending.name, false)} className="bg-red-500 py-1 px-3 w-[48%]">Reject</button>
                                            <a href={pending.receiptUrls} target='_blank' className='bg-yellow-500 py-1 px-3 mt-2 w-full'>See Receipt</a>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {activeTab === "notifications" && (
                        <div className="overlay">
                            <h2 className="text-center font-semibold text-xl mb-5">Send Notification</h2>
                            <form onSubmit={handleNotificationSubmit} className="flex flex-col max-w-[768px] mx-auto gap-5">
                                <input type="text"
                                    value={notificationTitle}
                                    onChange={(e) => setNotificationTitle(e.target.value)}
                                    placeholder="Enter notification Title..."
                                    className="border p-2 rounded"
                                    required
                                />
                                <textarea
                                    value={notificationMessage}
                                    onChange={(e) => setNotificationMessage(e.target.value)}
                                    placeholder="Enter notification message..."
                                    className="border p-2 rounded"
                                    required
                                ></textarea>
                                <input
                                    type="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                    className="border p-2 rounded"
                                />
                                <button type="submit" className="bg-blue-400 text-white py-2 px-4 rounded">
                                    Send Notification
                                </button>
                            </form>
                        </div>
                    )}

                    {activeTab === "ads" && (
                        <div className="overlay">
                            <h2 className="text-center font-semibold text-xl mb-5">Upload Advertisement</h2>
                            <Advertisement />
                        </div>
                    )}

                    {activeTab === "services" && (
                        <ServiceManagement />
                    )}
                    {activeTab === "blogs" && (
                        <CreateBlog />
                    )}
                </>
            )}

            {popupVisible && (
                <div className="popup-overlay">
                    <div className="popup">
                        <h2>Send Notification</h2>
                        <form onSubmit={e => { e.preventDefault(); currentListingId ? approveListing() : rejectListing(); }}>
                            <div className="flex flex-col mb-4">
                                <label htmlFor="popupEmail" className="mb-1">Email:</label>
                                <input
                                    type="email"
                                    id="popupEmail"
                                    value={popupEmail}
                                    onChange={(e) => setPopupEmail(e.target.value)}
                                    className="border p-2 rounded"
                                    required
                                />
                            </div>
                            <div className="flex flex-col mb-4">
                                <label htmlFor="popupMessage" className="mb-1">Message:</label>
                                <textarea
                                    id="popupMessage"
                                    value={popupMessage}
                                    onChange={(e) => setPopupMessage(e.target.value)}
                                    className="border p-2 rounded"
                                    required
                                ></textarea>
                            </div>
                            <button type="submit" className="bg-green-500 text-white py-2 px-4 rounded mr-2">Send</button>
                            <button type="button" onClick={() => setPopupVisible(false)} className="bg-red-500 text-white py-2 px-4 rounded">Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Admin;
