import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import Spinner from '../components/Spinner';

export default function NotificationDetails() {
  const params = useParams();
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const { notificationId } = params;

    if (!notificationId) {
      setError('Notification ID is missing from the URL');
      setLoading(false);
      return;
    }

    const fetchNotification = async () => {
      try {
        const docRef = doc(db, 'notifications', notificationId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setNotification(docSnap.data());
          setLoading(false);
        } else {
          setError('Notification not found');
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching notification:', error);
        setError('Error fetching notification');
        setLoading(false);
      }
    };

    fetchNotification();
  }, [params]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!notification) {
    return <div>No notification found</div>;
  }

  return (
    <div className="notification-details max-w-[768px] mx-auto text-center mt-[30px]">
      <h2 className="text-[25px] font-bold">{notification.title}</h2>
      <p className="text-1[18px]">{notification.message}</p>
      {notification.imageUrl && <img src={notification.imageUrl} alt="Notification" />}
      {/* <button onClick={() => handle any action here}>Close</button> */}
    </div>
  );
}
