// src/components/ServiceManagement.jsx
import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Make sure auth is imported
import { addDoc, collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import ServiceItem from './ServiceItem';

const ServiceManagement = () => {
    const [serviceName, setServiceName] = useState("");
    const [servicePrice, setServicePrice] = useState("");
    const [serviceCategory, setServiceCategory] = useState("fashion");
    const [serviceImage, setServiceImage] = useState(null);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);

    const categories = [
        { id: "fashion", name: "Fashion" },
        { id: "cars", name: "Cars" },
        { id: "estate", name: "Estate" },
        { id: "beauty", name: "Beauty" },
        { id: "architecture", name: "Architecture" },
        { id: "agriculture", name: "Agricultural Products" },
        { id: "horticulture", name: "Horticulture" },
        { id: "school", name: "School Lesson Tutor" },
        { id: "gardener", name: "Gardener" },
    ];

    useEffect(() => {
        fetchServices();
    }, []);

    const fetchServices = async () => {
        try {
            const servicesCollection = collection(db, "services");
            const servicesSnapshot = await getDocs(servicesCollection);
            const servicesData = servicesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setServices(servicesData);
        } catch (error) {
            console.error("Error fetching services", error);
            toast.error("Error fetching services!");
        }
    };

    // const storeImage = async (image) => {
    //     return new Promise((resolve, reject) => {
    //         const filename = `${uuidv4()}-${image.name}`;
    //         const storageRef = ref(getStorage, filename);
    //         const uploadTask = uploadBytesResumable(storageRef, image);
    //         uploadTask.on(
    //             "state_changed",
    //             (snapshot) => {
    //                 const progress =
    //                     (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //                 console.log("Upload is " + progress + "% done");
    //             },
    //             (error) => {
    //                 reject(error);
    //             },
    //             () => {
    //                 getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //                     resolve(downloadURL);
    //                 });
    //             }
    //         );
    //     });
    // };

    const handleServiceSubmit = async (e) => {
        e.preventDefault();
        // if (!serviceImage) {
        //     toast.error("Please upload an image!");
        //     return;
        // }

        setLoading(true);

        try {
            // const imgUrl = await storeImage(serviceImage);

            await addDoc(collection(db, "services"), {
                name: serviceName,
                price: servicePrice,
                category: serviceCategory,
                // imgUrl: imgUrl,
                timestamp: new Date(),
            });

            toast.success("Service added successfully!");
            setServiceName("");
            setServicePrice("");
            setServiceCategory("fashion");
            // setServiceImage(null);
            fetchServices();
        } catch (error) {
            console.error("Error adding service:", error);
            toast.error("Error adding service!");
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteService = async (id) => {
        const confirmDelete = window.confirm("Do you want to delete this service?");
        if (confirmDelete) {
            try {
                await deleteDoc(doc(db, "services", id));
                toast.success("Service deleted successfully!");
                fetchServices();
            } catch (error) {
                console.error("Error deleting service:", error);
                toast.error("Error deleting service!");
            }
        }
    };

    return (
        <div className="service-management">
            <h2 className="text-center font-bold text-2xl mb-4">Service Management</h2>
            <form onSubmit={handleServiceSubmit} className="max-w-[768px] mx-auto gap-5">
                <div className="flex flex-col mb-4 w-[350px] max-w-[768px] mx-auto">
                    <label htmlFor="serviceName" className="mb-1">Service Name:</label>
                    <input
                        type="text"
                        id="serviceName"
                        value={serviceName}
                        onChange={(e) => setServiceName(e.target.value)}
                        required
                        className="border p-2 rounded"
                    />
                </div>
                <div className="flex flex-col mb-4 w-[350px] max-w-[768px] mx-auto">
                    <label htmlFor="servicePrice" className="mb-1">Service Price:</label>
                    <input
                        type="number"
                        id="servicePrice"
                        value={servicePrice}
                        onChange={(e) => setServicePrice(e.target.value)}
                        required
                        className="border p-2 rounded"
                    />
                </div>
                <div className="flex flex-col mb-4 w-[350px] max-w-[768px] mx-auto">
                    <label htmlFor="serviceCategory" className="mb-1">Service Category:</label>
                    <select
                        id="serviceCategory"
                        value={serviceCategory}
                        onChange={(e) => setServiceCategory(e.target.value)}
                        required
                        className="border p-2 rounded"
                    >
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>
                {/* <div className="flex flex-col mb-4">
                    <label htmlFor="serviceImage" className="mb-1">Service Image:</label>
                    <input
                        type="file"
                        id="serviceImage"
                        onChange={(e) => setServiceImage(e.target.files[0])}
                        accept="image/*"
                        required
                        className="border p-2 rounded"
                    />
                </div> */}
                <button type="submit" className="bg-blue-400 max-w-[200px] mx-auto mb-5 flex justify-center text-white py-2 px-4 rounded mt-4" disabled={loading}>
                    {loading ? "Uploading..." : "Add Service"}
                </button>
            </form>

            <div>
                <h2 className="text-center font-bold text-2xl mb-4">Existing Services</h2>
                <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                    {services.map((service) => (
                        <ServiceItem
                            key={service.id}
                            id={service.id}
                            service={service}
                            onDelete={handleDeleteService}
                            onEdit={() => {}}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ServiceManagement;
