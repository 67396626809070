import { useState } from "react";
import search from "../assets/svg/search.png";
import heroImg from "../assets/images/bg4.png";
import SearchBar from "../components/SearchBar";
const Hero = ({ handleSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(searchTerm);
    // Redirect to search page or any other page as needed
    // history.push(`/search?query=${searchTerm}`);
  };

  return (
    <div
      className="w-full h-[500px] overflow-hidden bg-no-repeat bg-image{heroImg}"
      style={{
        background: `url(${heroImg}) center, no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <div className="md-down:mt-[63px] text-center mt-[55px] max-w-[1100px] mx-auto">
        <h2 className="text-[#fff] text-[50px] md-down:text-[30px] md-down:mx-2 font-bold">
        Find local professionals for pretty much anything you need. 
        {/* <br className="md-down:hidden" /> your<span className="text-[#F6D200]"> Business</span> */}
        </h2>
        {/* <p className="text-[18px] max-w-[400px] mx-auto text-white text-center">
          <span className="font-semibold">Swift Mart</span> is here to make your business go public,
          at the price you want, in the style you want <br className="md-down:hidden" /> Send us a message with the button below.
        </p> */}
        <div className="mt-5">
        <SearchBar />
      </div>
      </div>

    </div>
  );
}

export default Hero;