import React, { useEffect, useState } from "react";
import {
    collection,
    getDocs,
    limit,
    orderBy,
    query,
    startAfter,
    where,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../firebase";
import Spinner from "../components/Spinner";
import { useParams } from "react-router-dom";
import ServiceItem from "../components/ServiceItem";

export default function Services() {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastFetchedService, setLastFetchedService] = useState(null);
    const params = useParams();

    useEffect(() => {
        const fetchServices = async () => {
            setLoading(true);
            try {
                const serviceRef = collection(db, "services");
                const q = query(
                    serviceRef,
                    where("services", "==", params.name),
                    orderBy("timestamp", "desc"),
                    limit(4)
                );
                const querySnap = await getDocs(q);

                const lastVisible = querySnap.docs[querySnap.docs.length - 1];
                setLastFetchedService(lastVisible);

                const servicesList = querySnap.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                }));

                setServices(servicesList);
            } catch (error) {
                console.error("Error fetching services:", error);
                toast.error("Could not fetch services");
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, [params.name]);

    const onFetchMoreServices = async () => {
        try {
            const serviceRef = collection(db, "services");
            const q = query(
                serviceRef,
                where("services", "==", params.name),
                orderBy("timestamp", "desc"),
                startAfter(lastFetchedService),
                limit(4)
            );
            const querySnap = await getDocs(q);

            const lastVisible = querySnap.docs[querySnap.docs.length - 1];
            setLastFetchedService(lastVisible);

            const newServices = querySnap.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            }));

            setServices(prevState => [...prevState, ...newServices]);
        } catch (error) {
            console.error("Error fetching more services:", error);
            toast.error("Could not fetch services");
        }
    };

    return (
        <div className="max-w-6xl mx-auto px-3">
            <h1 className="text-3xl text-center mt-6 font-bold">
                {params.name === "fashion"
                    ? "Fashion Services"
                    : params.name === "cars"
                    ? "Cars Services"
                    : "Houses Services"}
            </h1>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {services.length > 0 ? (
                        <main>
                            <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                                {services.map(service => (
                                    <ServiceItem
                                        key={service.id}
                                        id={service.id}
                                        service={service.data}
                                    />
                                ))}
                            </ul>
                        </main>
                    ) : (
                        <p>There are no current services in this category</p>
                    )}
                    {lastFetchedService && services.length > 0 && (
                        <div className="flex justify-center items-center">
                            <button
                                className="bg-white px-3 py-1.5 text-gray-700 border border-gray-300 mb-6 mt-6 hover:border-slate-600 rounded transition ease-in-out duration-150"
                                onClick={onFetchMoreServices}
                            >
                                Load More
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
