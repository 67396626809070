import { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

const SearchBar = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const fetchListings = async () => {
            const listingsCollection = collection(db, "listings2");
            const listingsSnapshot = await getDocs(listingsCollection);
            const listingsList = listingsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setData(listingsList);
        };
        fetchListings();
    }, []);

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query); // Update searchQuery state
        if (query === '') {
            setFilteredData([]); // Clear filteredData when query is empty
        } else {
            const filtered = data.filter(listing => {
                const listingName = listing.name.toLowerCase();
                return listingName.includes(query);
            });
            setFilteredData(filtered);
        }
    };

    const displaySearchResults = () => {
        if (searchQuery === '') {
            return []; // Return empty array when searchQuery is empty
        }
        return filteredData.map((item, id) => (
            <div key={item.id} className="mb-2">
                <a href={`/search/${item.type}/${item.id}`} className="font-semibold">
                    {item.name}
                </a>
            </div>
        ));
    };

    return (
        <div className="flex Justify-center align center max-w-md w-sm:280px mx-auto">
            <div className="w-full mx-5">
                <input
                    type='search'
                    value={searchQuery}
                    onChange={handleSearch}
                    placeholder="Search Providers..."
                    className="rounded-md w-full"
                />
                <ul id="search-results" className="bg-white">
                    {displaySearchResults()}
                </ul>
            </div>
        </div>
    );
};

export default SearchBar;
