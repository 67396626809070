import React from 'react';

const Popup = ({ ad, onClose }) => {
    const handleClose = () => {
        onClose();
    };

    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('popup-overlay')) {
            onClose();
        }
    };

    return (
        <div className="z-[9999] fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 popup-overlay" onClick={handleOutsideClick}>
            <div className="bg-white rounded-lg shadow-lg overflow-y-auto">
                <button
                    className="float-right text-white bg-red-500 rounded-full w-8 h-8 flex items-center justify-center"
                    onClick={handleClose}
                >
                    X
                </button>
                {ad.adType === 'image' ? (
                    <a href={ad.link} target="_blank" rel="noopener noreferrer">
                        <img src={ad.imageUrl} alt="Advertisement" className="max-w-[300px] w-full" />
                    </a>
                ) : (
                    <div>
                        <p>{ad.content}</p>
                        <a href={ad.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                            Learn More
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Popup;
