import { Link } from "react-router-dom";
import Moment from "react-moment";
import { FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

const BlogItem = ({ blog, id, onEdit, onDelete }) => {
  return (
    <li className="relative bg-white flex flex-col justify-between items-center shadow-md hover:shadow-xl rounded-md overflow-hidden transition-shadow duration-150 m-[10px]">
      <Link className="contents" to={`/blog/${id}`}>
        {blog.imageUrl && (
          <img
            className="h-[170px] w-full object-cover hover:scale-110 transition-scale duration-200 ease-in"
            loading="lazy"
            src={blog.imageUrl}
            alt={blog.title}
          />
        )}
        <Moment
          className="absolute top-2 left-2 bg-[#3377cc] text-white uppercase text-xs font-semibold rounded-md px-2 py-1 shadow-lg"
          fromNow
        >
          {blog.createdAt?.toDate()}
        </Moment>
        <div className="w-full p-[10px]">
          <p className="font-semibold text-xl mb-2 truncate">{blog.title.slice(0, 10)}...</p>
          <p className="text-gray-600 truncate">{blog.content.slice(0, 15)}...</p>
        </div>
      </Link>
      {onDelete && (
        <FaTrash
          className="absolute bottom-2 right-2 h-[14px] cursor-pointer text-red-500"
          onClick={() => onDelete(blog.id)}
        />
      )}
      {onEdit && (
        <MdEdit
          className="absolute bottom-2 right-7 h-4 cursor-pointer"
          onClick={() => onEdit(blog.id)}
        />
      )}
    </li>
  );
};

export default BlogItem;
