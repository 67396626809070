import { useEffect, useState } from "react";
import Slider from "../components/Slider";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import { Link } from "react-router-dom";
import Popup from "../components/PopupAd";
import Hero from "../components/Hero";
import { toast } from "react-toastify";

import ListingItem from "../components/ListingItem";
import SearchBar from "../components/SearchBar";
import Category from "../components/Category";
import BlogItem from "../components/BlogItem"

export default function Home() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const q = query(collection(db, 'notifications'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let notificationsData = [];
      querySnapshot.forEach((doc) => {
        notificationsData.push({ id: doc.id, data: doc.data() });
      });
      setNotifications(notificationsData);
      setLoading(false);
    }, (err) => {
      setError(err);
      setLoading(false);
    });

    return () => {
      // Unsubscribe from the snapshot listener when component unmounts
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Check for unread notifications and show toast
    const unreadNotifications = notifications.filter(notification => !notification.data.read);
    if (unreadNotifications.length > 0) {
      toast.info('New notification unread');
    }
  }, [notifications]);

  //Offers
  const [offerListings, setOfferListings] = useState(null);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        //get reference
        //Listings ref and we can use the collection which is coming from fa fa store which takes the DB, which is coming from the fire.
        const listingsRef = collection(db, "listings");
        //Now we can create the query to query is the limit or the condition of that request.
        const q = query(
          listingsRef,
          where("offer", "==", true),
          orderBy("timestamp", "desc"),
          limit(4)
        );
        //execute the query
        const querySnap = await getDocs(q);
        const listings = [];
        //And after that, you're going to use for each to loop through the snap that we are getting from here.
        querySnap.forEach((doc) => {
          //This one, we're going to push each doc inside this, but we're going to push it as a object because we want to get the ID first, which is equal to doc that ID.
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setOfferListings(listings);
        //console.log(listings)
      } catch (error) {
        console.log(error);
      }
    };
    fetchListings();
  }, []);
  const [ad, setAd] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const adsCollection = collection(db, 'ads');
        const adsSnapshot = await getDocs(adsCollection);
        const adsData = adsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAd(adsData[0]); // Get the first ad for the popup
        setShowPopup(true); // Show the popup
      } catch (error) {
        console.error('Error fetching ads:', error);
      }
    };

    fetchAds();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  //Places for Fashion
  const [fashionListings, setFashionListings] = useState(null);
  const [blogListings, setBlogListings] = useState(null);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogsCollection = collection(db, 'blogs');
        const blogsSnapshot = await getDocs(blogsCollection);
        const blogsData = blogsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogsData);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        toast.error('Error fetching blogs!');
      }
    };

    fetchBlogs();
  }, []);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        //get reference
        //Listings ref and we can use the collection which is coming from fa fa store which takes the DB, which is coming from the fire.
        const listingsRef = collection(db, "listings");
        //Now we can create the query to query is the limit or the condition of that request.
        const q = query(
          listingsRef,
          where("type", "==", "fashion"),
          orderBy("timestamp", "desc"),
          limit(4)
        );
        //execute the query
        const querySnap = await getDocs(q);
        const listings = [];
        //And after that, you're going to use for each to loop through the snap that we are getting from here.
        querySnap.forEach((doc) => {
          //This one, we're going to push each doc inside this, but we're going to push it as a object because we want to get the ID first, which is equal to doc that ID.
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setFashionListings(listings);
        //console.log(listings)
      } catch (error) {
        console.log(error);
      }
    };
    fetchListings();
  }, []);

  //Places for Sales
  const [beautyListings, setBeautyListings] = useState(null);




  useEffect(() => {
    const fetchListings = async () => {
      try {
        //get reference
        //Listings ref and we can use the collection which is coming from fa fa store which takes the DB, which is coming from the fire.
        const listingsRef = collection(db, "listings");
        //Now we can create the query to query is the limit or the condition of that request.
        const q = query(
          listingsRef,
          where("type", "==", "beauty"),
          orderBy("timestamp", "desc"),
          limit(4)
        );
        //execute the query
        const querySnap = await getDocs(q);
        const listings = [];
        //And after that, you're going to use for each to loop through the snap that we are getting from here.
        querySnap.forEach((doc) => {
          //This one, we're going to push each doc inside this, but we're going to push it as a object because we want to get the ID first, which is equal to doc that ID.
          return listings.push({
            id: doc.id,
            data: doc.data(),
          });
        });
        setBeautyListings(listings);
        //console.log(listings)
      } catch (error) {
        console.log(error);
      }
    };
    fetchListings();
  }, []);


  return (
    <div>
      <Hero />
      <div className="categories mt-5 items-center flex flex-wrap max-w-[768px] mx-auto justify-center gap-3">
        <Category name="fashion" />
        <Category name="beauty" />
        <Category name="estate" />
        <Category name="cars" />
        <Category name="architecture" />
        <Category name="agriculture" />
        <Category name="horticulture" />
        <Category name="school" />
        <Category name="gardener" />
<Category name="declutter" />
        {/* Add other categories as needed */}
      </div>
      <div className="max-w-6xl mx-auto pt-4 space-y-6">
        {
          //We just say if the offer listing exists.
          //We want to see if the offer listings length is greater than zero.
          offerListings && offerListings.length > 0 && (
            <div className="m-2 mb-6">
              <h2 className="px-3 text-2xl mt-6 font-semibold text-center">
                Recent offers
              </h2>
              <Link to="/offers">
                <p className="px-3 text-sm text-blue-400 hover:text-blue-400 transition duration-150 ease-in-out text-center">
                  Show more offers
                </p>
              </Link>
              <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {offerListings.map((listing) => (
                  <ListingItem
                    key={listing.id}
                    listing={listing.data}
                    id={listing.id}
                  />
                ))}
              </ul>
            </div>
          )}




        {
          //We just say if the offer listing exists.
          //We want to see if the offer listings length is greater than zero.
          fashionListings && fashionListings.length > 0 && (
            <div className="m-2 mb-6">
              <h2 className="px-3 text-2xl mt-6 font-semibold text-center">
                Fashion Services
              </h2>
              <Link to="/category/fashion">
                <p className="px-3 text-sm text-center text-blue-400 hover:text-blue-400 transition duration-150 ease-in-out">
                  Show more fashion services
                </p>
              </Link>
              <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {fashionListings.map((listing) => (
                  <ListingItem
                    key={listing.id}
                    listing={listing.data}
                    id={listing.id}
                  />
                ))}
              </ul>
            </div>
          )}

       
        {
          //We just say if the offer listing exists.
          //We want to see if the offer listings length is greater than zero.
          beautyListings && beautyListings.length > 0 && (
            <div className="m-2 mb-6">
              <h2 className="px-3 text-2xl mt-6 font-semibold text-center">
                Beauty Services
              </h2>
              <Link to="/category/beauty">
                <p className="px-3 text-sm text-center text-blue-400 hover:text-blue-800 transition duration-150 ease-in-out">
                  Show more beauty services
                </p>
              </Link>
              <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-[100px]">
                {beautyListings.map((listing) => (
                  <ListingItem
                    key={listing.id}
                    listing={listing.data}
                    id={listing.id}
                  />
                ))}
              </ul>
            </div>
          )
        }

<div className="m-2 mb-6">
          <h2 className="px-3 text-2xl mt-6 font-semibold text-center">
            Our Blogs
          </h2>
          <Link to="/blogs">
            <p className="px-3 text-sm text-blue-400 hover:text-blue-400 transition duration-150 ease-in-out text-center">
              Show more blogs
            </p>
          </Link>
          <ul className="sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {blogs.map((blog) => (
              <BlogItem
                key={blog.id}
                blog={blog}
                id={blog.id}
              />
            ))}
          </ul>
        </div>
      </div>
      {showPopup && ad && <Popup ad={ad} onClose={handleClosePopup} />}
    </div>
  );
}
