import "./App.css";
// import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import PrivateRoute from "./components/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword";
import Offers from "./pages/Offers";
import Header from "./components/Header";
import Footer from "./components/Footer";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateListing from "./pages/CreateListing";
import CreateBlog from "./pages/CreateBlog";
import EditBlog from "./pages/EditBlog";
import EditListing from "./pages/EditListing";
import Blogs from "./pages/Blog";
import Listing from "./pages/Listing";
import Category from "./pages/Category";
import Properties from "./pages/Properties";
import About from "./pages/About";
import PublicProfile from "./pages/PublicProfile";
import FriendsList from "./pages/FriendsList";
import Admin from "./pages/Admin";
// import useAdvertisement from "./hooks/useAdvertisement";
import NotificationDetails from "./pages/NotificationDetails";
import Notification from "./components/Notification";
import Services from "./pages/Service";
import OurBlogs from "./pages/OurBlog";
import Listing2 from "./pages/Listing2";
import Privacy from "./pages/Privacy";
import Contact from "./pages/Contact";

function App() {
  // const advertisement = useAdvertisement();

  return (
    <>
      {/* {advertisement && (
        <div className="advertisement">
          {advertisement.adType === 'image' ? null : (
            <div className="text-advertisement">
              <marquee behavior="scroll" direction="left" scrollamount="5">
                {advertisement.content}
              </marquee>
              <a href={advertisement.link} target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </div>
          )}
        </div>
      )} */}
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/profile" element={<PrivateRoute key={window.location.pathname} />}>
<Route path="/profile" element={<Profile />} />
</Route>

          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/hire" element={<Properties />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/category/:categoryName" element={<Category />} />
          <Route path="/category/service/:name" element={<Services />} />

          <Route path="/category/:categoryName/:listingId" element={<Listing />} />
          <Route path="/search/:categoryName/:listingId" element={<Listing2 />} />

          <Route path="/blog/:blogId" element={<Blogs />} />
          <Route path="/blogs" element={<OurBlogs />} />

          <Route path="create-listing" element={<PrivateRoute />}>
            <Route path="/create-listing" element={<CreateListing />} />
          </Route>

          <Route path="create-blog" element={<PrivateRoute />}>
            <Route path="/create-blog" element={<CreateBlog />} />
          </Route>

          <Route path="edit-listing" element={<PrivateRoute />}>
            <Route path="/edit-listing/:listingId" element={<EditListing />} />
          </Route>

          <Route path="edit-blog" element={<PrivateRoute />}>
            <Route path="/edit-blog/:blogId" element={<EditBlog />} />
          </Route>

          <Route path="/user-profile" element={<PrivateRoute />}>
            <Route path=":name" element={<PublicProfile />} />
          </Route>

          <Route path="/friends" element={<PrivateRoute />}>
            <Route path="/friends" element={<FriendsList />} />
          </Route>
          <Route path="/notification" element={<PrivateRoute />}>
            <Route path="/notification" element={<Notification />} />
          </Route>
          <Route path="/notification/:notificationId" element={<PrivateRoute />}>
            <Route path="/notification/:notificationId" element={<NotificationDetails />} />
          </Route>

          <Route path="/admin" element={<PrivateRoute />}>
            <Route path="/admin" element={<Admin />} />
          </Route>

          {/* <PrivateRoute path="/user/:id"
            element={<PublicProfile id={id} />} /> */}

        </Routes>
        <Footer />
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* Same as */}
      <ToastContainer />

    </>
  );
}

export default App;
