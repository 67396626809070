import React from 'react';
import { Link } from 'react-router-dom';

const Category = ({ name }) => {
  return (
    <div className="category-item">
      <Link to={`/category/${name}`}>
        <button className="category-button bg-blue-400 py-2 px-5 rounded-lg text-white">{name}</button>
      </Link>
    </div>
  );
};

export default Category;
