import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc, updateDoc, arrayRemove, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import Popup from "../components/PopupAd"
import { RemoveFriendIcon } from "../components/icons";

export default function FriendsList() {
  const [friends, setFriends] = useState([]);
  const [user, setUser] = useState(null);
  const auth = getAuth();
  const [ad, setAd] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const fetchAds = async () => {
            try {
                const adsCollection = collection(db, 'ads');
                const adsSnapshot = await getDocs(adsCollection);
                const adsData = adsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAd(adsData[0]); // Get the first ad for the popup
                setShowPopup(true); // Show the popup
            } catch (error) {
                console.error('Error fetching ads:', error);
            }
        };

        fetchAds();
    }, []);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

  useEffect(() => {
    const fetchFriends = async () => {
      try {
        const userRef = doc(db, 'users', auth.currentUser.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUser(userData);

          if (userData.friends && Array.isArray(userData.friends)) {
            setFriends(userData.friends);
          } else {
            console.log("No friends found.");
            toast.info("No friends found");
          }
        } else {
          console.error('No user found with the current user ID');
          toast.info("No user found");
        }
      } catch (error) {
        console.error("Error fetching friends:", error);
        toast.error("Error fetching friends");
      }
    };

    fetchFriends();
  }, [auth.currentUser.uid]);

  const removeFriend = async (friendName) => {
  try {
    const userDocRef = doc(db, "users", auth.currentUser.uid);

    // Update Firestore document to remove the friend
    await updateDoc(userDocRef, {
      friends: arrayRemove(friendName),
    });

    // Verify the update by fetching the document again
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const updatedFriends = userDoc.data().friends;

      // Ensure the friend is removed
      if (!updatedFriends.includes(friendName)) {
        setFriends(prevFriends => prevFriends.filter(friend => friend.name !== friendName));
        toast.success(`Removed ${friendName} from friends!`);
      } else {
        console.error("Failed to remove friend from Firestore");
        toast.error("Failed to remove friend from Firestore");
      }
    } else {
      console.error("User document does not exist");
      toast.error("User document does not exist");
    }
  } catch (error) {
    console.error("Error removing friend:", error);
    toast.error("Error removing friend");
  }
};

  return (
    <div className="max-w-[600px] mx-auto mt-10 px-4">
      {showPopup && ad && <Popup ad={ad} onClose={handleClosePopup} />}
      <h1 className="text-3xl font-semibold mb-4">Friends List</h1>
      {friends.map((friend) => (
      <div key={friend.name} className="flex justify-between align-center items-center mb-4">
        <Link to={`/user-profile/${friend.name}`} className="flex justify-between align-center items-center">
          <img
            src={friend.photoURL}
            alt="Profile"
            className="w-20 h-20 object-cover rounded-full cursor-pointer"
          />
          <span className="font-semibold text-[20px] ml-3">{friend.name}</span>
        </Link>
     {/*   <button 
        title="Remove Friend"
          onClick={() => removeFriend(friend.name)} 
          className="hover:title ml-4 bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded">
          <RemoveFriendIcon />
        </button> */}
      </div>
    ))}
    </div>
  );
}