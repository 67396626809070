import { useState, useEffect } from "react";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import { addDoc, collection, serverTimestamp, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";

export default function CreateListing() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");

  const [formData, setFormData] = useState({
    type: "fashion",
    name: "",
    quantity: 1,
    address: "",
    homeAddress: "",
    state: "",
    city: "",
    email: "",
    description: "",
    offer: false,
    regularPrice: 0,
    discountedPrice: 0,
    latitude: 0,
    longitude: 0,
    images: {},
    receipts: {},
  });

  const {
    type,
    name,
    quantity,
    address,
    homeAddress,
    state,
    city,
    email,
    description,
    offer,
    regularPrice,
    discountedPrice,
    latitude,
    longitude,
    images,
    receipts,
  } = formData;

  useEffect(() => {
    const fetchServices = async () => {
      const servicesRef = collection(db, "services");
      const servicesSnapshot = await getDocs(servicesRef);
      const servicesList = servicesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesList);
    };

    fetchServices();
  }, []);

  const handleServiceChange = (service) => {
    setSelectedService(service);
  };

  const onChange = (e) => {
    let boolean = null;
    if (e.target.value === "true") {
      boolean = true;
    }
    if (e.target.value === "false") {
      boolean = false;
    }
  
    if (e.target.files) {
      // Check if the input is for images or receipts
      if (e.target.id === "images") {
        // Update images
        setFormData((prevState) => ({
          ...prevState,
          images: e.target.files,
        }));
      } else if (e.target.id === "receipts") {
        // Update receipts
        setFormData((prevState) => ({
          ...prevState,
          receipts: e.target.files,
        }));
      }
    } else {
      // For other input types, update the state as before
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: boolean ?? e.target.value,
      }));
    }
  };
  

  

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (+discountedPrice >= +regularPrice) {
      setLoading(false);
      toast.error("Discounted price needs to be less than the regular price");
      return;
    }
    if (images.length > 5) {
      setLoading(false);
      toast.error("Maximum 5 images are allowed");
      return;
    }
    if (receipts.length > 3) {
      setLoading(false);
      toast.error("Maximum 3 images are allowed");
      return;
    }

    const storeImage = async (image) => {
      return new Promise((resolve, reject) => {
        const storage = getStorage();
        const filename = `${auth.currentUser.uid}-${image.name}-${uuidv4()}`;
        const storageRef = ref(storage, filename);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    };

    const imgUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch((error) => {
      setLoading(false);
      toast.error("Images not uploaded");
      return;
    });
    const receiptUrls = await Promise.all(
      [...receipts].map((image) => storeImage(image))
    ).catch((error) => {
      setLoading(false);
      toast.error("Receipts not uploaded");
      return;
    });

    const { id, ...formDataWithoutId } = formData;
    const formDataCopy = {
      ...formData,
      imgUrls,
      receiptUrls,
      homeAddress,
      selectedService, // Add the selected service
      timestamp: serverTimestamp(),
      userRef: auth.currentUser.uid,
      status: "pending", // Set the status to pending for admin approval
    };

    delete formDataCopy.images;
    delete formDataCopy.receipts;
    !formDataCopy.offer && delete formDataCopy.discountedPrice;

    const formDataCopy2 = {
      ...formDataWithoutId,
      imgUrls,
      receiptUrls,
      homeAddress,
      selectedService, // Add the selected service
      timestamp: serverTimestamp(),
      userRef: auth.currentUser.uid,
    };

    delete formDataCopy2.images;
    delete formDataCopy2.receipts;
    !formDataCopy2.offer && delete formDataCopy2.discountedPrice;

    await addDoc(collection(db, "pending"), formDataCopy);
    await addDoc(collection(db, "listings2"), formDataCopy2);

    // Prepare email details
    const email1 = "unityekeoba18@gmail.com";
    const email2 = "nexpixel19@gmail.com";
    const status = "created";
    const pendingName = formData.title || "New listing";

    // Construct mailto URI
    const mailtoURI = `mailto:${email1},${email2}?subject=Listing has been ${status}&body=listing "${pendingName}" is waiting for approval`;

    // Send email
    window.location.href = mailtoURI;

    setLoading(false);
    toast.success("Listing created and sent for approval");
    navigate(`/profile`);
};


  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="max-w-[1000px] px-2 mx-auto">
      <h1 className="text-3xl text-center mt-6 font-bold">Create a Listing</h1>
      <form onSubmit={onSubmit}>
        <p className="text-lg mt-6 font-semibold">Select a category</p>
        <div className="flex flex-wrap align-center gap-4">
          {/* Category buttons */}
          <button
            type="button"
            className={`px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out 
             ${type === "fashion" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="fashion"
            onClick={onChange}
          >
            Fashion Designer
          </button>
          <button
            type="button"
            className={`ml-0 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out  
            ${type === "beauty" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="beauty"
            onClick={onChange}
          >
            Beauty Product
          </button>
          <button
            type="button"
            className={`ml-0 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out  
            ${type === "cars" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="cars"
            onClick={onChange}
          >
            Sales and Buying of Cars
          </button>
          <button
            type="button"
            className={`ml-0 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out  
            ${type === "estate" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="estate"
            onClick={onChange}
          >
            Real Estate Management
          </button>
          <button
            type="button"
            className={`ml-0 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out  
            ${type === "architecture" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="architecture"
            onClick={onChange}
          >
            Architecture
          </button>
          <button
            type="button"
            className={`ml-0 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out  
            ${type === "agriculture" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="agriculture"
            onClick={onChange}
          >
            Sales of Agricultural Products
          </button>
          <button
            type="button"
            className={`ml-0 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out  
            ${type === "horticulture" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="horticulture"
            onClick={onChange}
          >
            Horticulture
          </button>
          <button
            type="button"
            className={`ml-0 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out  
            ${type === "school" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="school"
            onClick={onChange}
          >
            School Lesson Tutor
          </button>
          <button
            type="button"
            className={`ml-0 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out  
            ${type === "gardener" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="gardener"
            onClick={onChange}
          >
            Gardener
          </button>
          <button
            type="button"
            className={`ml-0 px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out  
            ${type === "declutter" ? "bg-white text-black" : "bg-slate-600 text-white"
              }`}
            id="type"
            value="declutter"
            onClick={onChange}
          >
            Declutter
          </button>
          {/* Additional category buttons */}
        </div>

        {/* Services section */}
        <div className="mt-6">
          <p className="text-lg font-semibold">Select a Service</p>
          <div className="flex flex-col gap-4">
            {services
              .filter((service) => service.category === type)
              .map((service) => (
                <div key={service.name} className="flex items-center">
                  <input
                    type="radio"
                    id={service.name}
                    name="selectedService"
                    value={service.name}
                    onChange={() => handleServiceChange({ name: service.name, price: service.price })}
                    checked={selectedService?.name === service.name}
                  />
                  <label htmlFor={service.id} className="ml-2">
                    {service.name} - ₦{service.price}
                  </label>
                </div>
              ))}
          </div>
        </div>

        <div className="max-w-md mx-auto">
          {/* Name input */}
          <p className="text-lg mt-6 font-semibold">Name</p>
          <input
            type="text"
            id="name"
            value={name}
            onChange={onChange}
            placeholder="Name"
            maxLength="52"
            minLength={5}
            required
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate mb-6"
          />
          {/* Quantity input */}
          {/* <div className="flex justify-start space-x-6">
            <div>
              <p className="text-lg font-semibold">Quantity</p>
              <input
                type="number"
                id="quantity"
                value={quantity}
                onChange={onChange}
                min="1"
                max="500"
                required
                className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-700 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center"
              />
            </div>
          </div> */}

          {/* State input */}
          <div className="flex justify-start space-x-6">
            <div>
              <p className="text-lg mt-6 font-semibold">State</p>
              <input
                type="text"
                id="state"
                value={state}
                onChange={onChange}
                placeholder="State"
                required
                className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate mb-6"
              />
            </div>
          </div>
          {/* City input */}
          <div className="flex justify-start space-x-6">
            <div>
              <p className="text-lg mt-6 font-semibold">City</p>
              <input
                type="text"
                id="city"
                value={city}
                onChange={onChange}
                placeholder="City"
                required
                className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate mb-6"
              />
            </div>
          </div>
          {/* Address input */}
          <p className="text-lg mt-6 font-semibold">Home Address</p>
          <textarea
            type="text"
            id="homeAddress"
            value={homeAddress}
            onChange={onChange}
            placeholder="Home Address"
            required
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate mb-6"
          />
          <p className="text-lg mt-6 font-semibold">Your Email</p>
          <input
            type="email"
            id="email"
            value={email}
            onChange={onChange}
            placeholder="Your Email"
            required
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate mb-6"
          />
          {/* Description input */}
          <p className="text-lg font-semibold">Description</p>
          <textarea
            type="text"
            id="description"
            value={description}
            onChange={onChange}
            placeholder="Description"
            required
            className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate mb-6"
          />
          {/* Offer buttons */}
          <p className="text-lg font-semibold">Offer</p>
          <div className="flex mb-6">
            <button
              type="button"
              id="offer"
              value={true}
              onClick={onChange}
              className={`px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full mr-3 ${offer ? "bg-white text-black" : "bg-slate-600 text-white"
                }`}
            >
              Yes
            </button>
            <button
              type="button"
              id="offer"
              value={false}
              onClick={onChange}
              className={`px-7 py-3 font-medium text-sm uppercase shadow-md rounded hover:shadow-lg focus:shadow-lg active:shadow-lg transition duration-150 ease-in-out w-full ${!offer ? "bg-white text-black" : "bg-slate-600 text-white"
                }`}
            >
              No
            </button>
          </div>
          {/* Price inputs */}
          <div className="flex items-center mb-6">
            <div>
              <p className="text-lg font-semibold">Regular Price</p>
              <div className="flex w-full justify-center items-center space-x-6">
                <input
                  type="number"
                  id="regularPrice"
                  value={regularPrice}
                  onChange={onChange}
                  min="50"
                  max="400000000"
                  required
                  className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center"
                />
              </div>
            </div>
          </div>
          {/* Discounted Price */}
          {offer && (
            <div className="flex items-center mb-6">
              <div>
                <p className="text-lg font-semibold">Discounted Price</p>
                <div className="flex w-full justify-center items-center space-x-6">
                  <input
                    type="number"
                    id="discountedPrice"
                    value={discountedPrice}
                    onChange={onChange}
                    min="50"
                    max="400000000"
                    required={offer}
                    className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 text-center"
                  />
                </div>
              </div>
            </div>
          )}
          {/* Image upload */}
          <div className="mb-6">
            <p className="text-lg font-semibold">Images</p>
            <p className="text-gray-600">The first image will be the cover (max 5)</p>
            <input
              type="file"
              id="images"
              onChange={onChange}
              accept=".jpg,.png,.jpeg"
              multiple
              required
              className="w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:border-slate-600"
            />
          </div>

           {/* Receipt upload */}
           <div className="mb-6">
            <p className="text-lg font-semibold">Your payment receipt</p>
            <p className="text-gray-600">Make payment to this (1245794497 Zenith Bank Zephaniah Okorie)</p>
            <input
              type="file"
              id="receipts"
              onChange={onChange}
              accept=".jpg,.png,.jpeg"
              multiple
              required
              className="w-full px-3 py-1.5 text-gray-700 bg-white border border-gray-300 rounded transition duration-150 ease-in-out focus:bg-white focus:border-slate-600"
            />
          </div>
          {/* Submit button */}
          <button
            type="submit"
            className="mb-6 w-full px-7 py-3 bg-blue-400 text-white font-medium text-sm uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            Create Listing
          </button>
        </div>
      </form>
    </main>
  );
}