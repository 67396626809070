import React, { useEffect, useRef } from "react";
import bg from "../assets/svg/Frame 1.png";
import {
    AiFillMail,
    AiFillEnvironment,
    // AiFillFacebook,
    // AiFillTwitterSquare,
    // AiFillInstagram,
    AiFillPhone,
} from "react-icons/ai";

function Contact() {
    const formRef = useRef(null);

    useEffect(() => {
        const form = formRef.current;
        const handleFormSubmit = (e) => {
            e.preventDefault();
            const formData = new FormData(form);
            const subject = formData.get('subject');
            const message = formData.get('message');
            const firstName = formData.get('firstName');
            const lastName = formData.get('lastName');
            const phone = formData.get('phone');
            const mailtoUrl = `mailto:unityekeoba18@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`First Name: ${firstName}\nLast Name: ${lastName}\nPhone: ${phone}\n\nMessage: ${message}`)}`;
            window.location.href = mailtoUrl;
        };

        form.addEventListener('submit', handleFormSubmit);
        return () => {
            form.removeEventListener('submit', handleFormSubmit);
        };
    }, []);

    return (
        <div className="max-w-6xl mx-auto">
            <h1 className="text-center text-[30px] font-semibold">Contact Us</h1>
            <p className="text-center">Any question or remarks? Just write us a message!</p>
            <div className="mt-5 flex flex-wrap justify-center items-center">
                <div
                    className="bg-image relative h-[500px] md-down:h-auto md-down:w-full md-down:text-center py-5 w-[400px] rounded-lg"
                    style={{
                        backgroundImage: `url(${bg})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}>
                    <div className="ml-[30px] md-down:ml-0 pt-[30px]">
                        <h2 className="text-white text-[25px]">Contact Information</h2>
                        <p className="text-white text-[12px]">Say something to start a live chat!</p>

                        <div className="text-white flex items-center mt-[50px] md-down:justify-center">
                            <AiFillPhone />
                            <p className="ml-5">+234 0983 093 45</p>
                        </div>
                        <div className="text-white flex items-center mt-4 md-down:justify-center">
                            <AiFillMail />
                            <p className="ml-5">unityekeoba18@gmail.com</p>
                        </div>
                        <div className="text-white flex items-center mt-4 md-down:justify-center">
                            <AiFillEnvironment />
                            <p className="ml-5">Lagos 10 apapa</p>
                        </div>
                    </div>
                </div>
                <div className="ml-[50px] md-down:ml-0 md-down:flex md-down:justify-center mt-[30px]">
                    <form id="contact-form" ref={formRef}>
                        <div className="flex flex-wrap justify-between md-down:justify-center">
                            <div className="w-[300px]">
                                <label htmlFor="firstName">First Name:</label>
                                <input className="md-down:w-full focus:outline-none active:outline-none bg-transparent block border-t-0 border-l-0 border-r-0 border-b-2 border-b-black" type="text" id="firstName" name="firstName" required />
                            </div>

                            <div className="w-[300px]"><label htmlFor="lastName">Last Name:</label>
                                <input className="md-down:w-full focus:outline-none active:outline-none bg-transparent block border-t-0 border-l-0 border-r-0 border-b-2 border-b-black" type="text" id="lastName" name="lastName" required /></div>
                        </div>

                        <div className="flex flex-wrap mt-5 md-down:justify-center">
                            <div className="w-[300px]">
                                <label htmlFor="email">Email:</label>
                                <input className="md-down:w-full focus:outline-none active:outline-none bg-transparent block border-t-0 border-l-0 border-r-0 border-b-2 border-b-black" type="email" id="email" name="email" required />
                            </div>

                            <div className="w-[300px]">
                                <label htmlFor="phone">Phone Number:</label>
                                <input className="md-down:w-full focus:outline-none active:outline-none bg-transparent block border-t-0 border-l-0 border-r-0 border-b-2 border-b-black" type="tel" id="phone" name="phone" required />
                            </div>
                        </div>

                        <div className="mt-5 md-down:mx-auto md-down:max-w-[300px]">
                            <label htmlFor="subject">Select Subject:</label>
                            <ul className="flex items-center gap-5 w-full flex-wrap md-down:justify-center">
                                <li className="flex items-center">
                                    <input type="radio" id="subject1" name="subject" value="General Inquiry" />
                                    <label htmlFor="subject1">General Inquiry</label>
                                </li>
                                <li className="flex items-center">
                                    <input type="radio" id="subject2" name="subject" value="Support Request" />
                                    <label htmlFor="subject2">Support Request</label>
                                </li>
                                <li className="flex items-center">
                                    <input type="radio" id="subject3" name="subject" value="Other" />
                                    <label htmlFor="subject3">Other</label>
                                </li>
                            </ul>
                        </div>

                        <div className="mt-5 md-down:mx-auto md-down:max-w-[300px]">
                            <label htmlFor="message">Message:</label>
                            <textarea className="md-down:w-full focus:outline-none active:outline-none w-[500px] bg-transparent block border-t-0 border-l-0 border-r-0 border-b-2 border-b-black" id="message" name="message" required placeholder="Write your message" ></textarea>
                        </div>

                        <button className="bg-blue-400 py-3 px-5 text-white rounded-lg mt-5 md-down:ml-2" type="submit">Send Message</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact;
