// import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
import logo from "../assets/svg/logo4.jpg";
// import { collection, addDoc } from "firebase/firestore";
// import { toast } from "react-toastify";
// import { db } from "../firebase";
// import {  SignInIcon} from "./icons";


const Footer = () => {
    // const [pageState, setPageState] = useState(<SignInIcon />);
    const location = useLocation();
    const navigate = useNavigate();

    const pathMatchRoute = (route) => {
        if (route === location.pathname) {
            return true;
        }
    };

    // const [showNotificationPopup, setShowNotificationPopup] = useState(false);

    // const sendNotification = async (message) => {
    //     try {
    //         const notificationData = {
    //             userId: auth.currentUser.uid,
    //             message: message,
    //             timestamp: new Date(),
    //         };

    //         await addDoc(collection(db, 'notifications'), notificationData);
    //         toast.success('Notification sent successfully');
    //     } catch (error) {
    //         console.error('Error sending notification:', error);
    //         toast.error('Could not send notification');
    //     }
    // };

    // const auth = getAuth();

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             setPageState("Profile");
    //         } else {
    //             setPageState("Sign In");
    //         }
    //     });
    // }, [auth]);
    return (
        <div className="left-0 right-0 bg-blue-400 border-t py-3 w-full text-white mt-5" >
            {/* <small className="text-sm font-semibold">Copyright | 2024 All Right Reserved</small> */}
            <div className="max-w-6xl mx-auto pt-5 flex justify-between md-down:flex-col md-down:px-3">
                <div>
                    <div>
                        <div className="w-[150px]">
                            <img src={logo} alt="logo" className="cursor-pointer w-[100%]" onClick={() => navigate("/")} />
                        </div>
                        {/* <p className="text-blue-300 text-[16px] p-0 font-medium self-end cursor-pointer" onClick={() => navigate("/")}>Zee Service Provider</p> */}
                    </div>
                    <p className="max-w-[300px] mt-5 text-[12px]">Zee Service Provider is a platform in Nigeria, where all service providers
                        can showcase whatever service they have by selecting the category that matches their service and meet
                        potential client</p>
                </div>
                <ul className="items-center">
                    <h2 className="font-semibold text-[20px]">Services</h2>
                    <li title="Home Page" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("#") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("#")}>Run advertisement</li>
                    <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/hire") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/hire")}>Hire a Pro</li>
                    <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("#") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("#")}>List Service</li>
                </ul>
                <ul className="items-center">
                    <h2 className="font-semibold text-[20px]">Quick Links</h2>
                    <li title="Home Page" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/")}>Home</li>
                    {/* <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/about") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/about")}>About Us</li> */}
                    {/* <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/products") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/products")}>Products</li> */}
                    <li title="Recent Offers" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white  ${pathMatchRoute("/offers") && "!text-blue-400"}`} onClick={() => navigate("/offers")}>Offers</li>
                    <li title="Your Friends" className={`hover:title cursor-pointer py-1 text-[15px] font-light  text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/friends") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/friends")}>Friends</li>
                    {/* <li title="Your Profile" className={`hover:title cursor-pointer py-1 text-[15px] font-medium text-white border-b-[3px] border-b-transparent ${(pathMatchRoute("/sign-in") || pathMatchRoute("/profile")) && "text-black !border-b-red-500"}`} onClick={() => navigate("/profile")}>
                        {pageState}
                    </li> */}
                </ul>
                <ul className="items-center">
                    <h2 className="font-semibold text-[20px]">Follow Us</h2>
                    <li title="Home Page" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("#") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("#")}>Twitter</li>
                    <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("#") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("#")}>Facebook</li>
                    <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("#") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("#")}>Instagram</li>
                </ul>
                <ul className="items-center">
                    <h2 className="font-semibold text-[20px]">Privacy</h2>
                    {/* <li title="Home Page" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/about") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/about")}>About Us</li> */}
                    <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/privacy") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/privacy")}>Privacy Policy</li>
                    <li title="Products" className={`hover:title cursor-pointer py-1 text-[15px] font-light text-white border-b-[3px] border-b-transparent ${pathMatchRoute("/contact") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/contact")}>Contact Us</li>
                </ul>
            </div>
        </div>
    )
}

export default Footer;