import first from "../assets/svg/first.svg"
import second from "../assets/svg/second.svg"
import third from "../assets/svg/third.svg"
import fourth from "../assets/svg/fourth.svg"
// import fifth from "../assets/svg/fifth.svg"
const About = () => {
    return (
        <main className="max-w-[1000px] px-2 mx-auto">
            <h1 className="text-3xl text-center mt-6 font-bold">About Us</h1>
            <div className="flex justify-between flex-wrap align-center items-center mt-[20px]">
                <div className="max-w-[500px] mx-auto">
                    <h2>Zee Service Finder is the best place to sell anything to real people.</h2>
                    <p>It is the biggest free online classified with an advanced security system.
                        We provide a simple hassle-free solution to sell and buy almost anything.
                    </p>


                    <h2 className="mt-5 font-semibold">Features</h2>
                    <ul>
                        <a className="block bg-gray-200 mt-3 py-2 pl-3" href="#sell">How to sell on Zee</a>
                        <a className="block bg-gray-200 mt-3 py-2 pl-3" href="#buy">How to buy on Zee</a>
                        <a className="block bg-gray-200 mt-3 py-2 pl-3" href="#safety">Safety</a>
                        {/* <a className="block bg-gray-200 mt-3 py-2 pl-3" href="">Sell Like a PRO!</a> */}
                    </ul>
                </div>
                <div className="w-[400px] max-w-[400px] mx-auto mt-5">
                    <img src={first} alt="about-pic" className="w-[100%]" />
                </div>
            </div>

            <div id="sell" className="flex justify-between flex-wrap align-center items-center mt-[50px]">
                <div className="w-[400px] max-w-[400px] mx-auto mt-5">
                    <img src={second} alt="about-pic" className="w-[100%]" />
                </div>
                <div className="max-w-[500px] mx-auto">
                    <h1 className="font-semibold text-[30px]">How to sell on Zee?</h1>
                    <p className="mt-5 text-[20px]">1. <span className="text-blue-400">Register</span></p>
                    <p>Register using your e-mail (or do it via Google). Make sure you’re entering a correct email, so your clients could reach you!</p>

                    <p className="mt-5 text-[20px]">2. <span className="text-blue-400">Make photos of your item.</span></p>
                    <p>Feel free to make a lot of photos using your smartphone. Make sure they show your item in the best light.</p>

                    <p className="mt-5 text-[20px]">3. <span className="text-blue-400">Press SELL.</span></p>
                    <p>Choose a proper category, upload your photos and write a clear title and full description of your item. Enter a fair price, select attributes and send your advert to review!</p>

                    <p className="mt-5 text-[20px]">4. <span className="text-blue-400">Answer the messages and calls from your clients!</span></p>
                    <p>If everything is ok with your advert, it’ll be on zee in a couple of hours after sending to moderation. We’ll send you a letter and notification when your advert goes live. Check your messages and be ready to earn money! Do you want to sell like a pro?</p>


    
                </div>

            </div>

            <div id="buy" className="flex justify-between flex-wrap align-center items-center mt-[50px]">
                
                <div className="max-w-[500px] mx-auto">
                    <h1 className="font-semibold text-[30px]">How to buy on Zee?</h1>
                    <p className="mt-5 text-[20px]">1. <span className="text-blue-400">Search for the item.</span></p>
                    <p>Find what you need using search panel and filters. We have over a million adverts, choose exactly what you are looking for.</p>

                    <p className="mt-5 text-[20px]">2. <span className="text-blue-400">Contact a seller.</span></p>
                    <p>You may use chat on zee or call them via phone. Discuss all the details, negotiate about the price.</p>

                    <p className="mt-5 text-[20px]">3. <span className="text-blue-400">Take your item or order a delivery.</span></p>
                    <p>We check our sellers carefully, but it’s always better to check twice, right? Meet a seller in public place and be sure to pay only after collecting your item.</p>

                    <p className="mt-5 text-[20px]">4. <span className="text-blue-400">Leave your feedback about the seller.</span></p>
                    <p>Feel free to tell us about your purchase. Your feedback will be published online on the seller’s page and will be very helpful for other buyers. Let’s build a safe and professional business community together!</p>


    
                </div>

                <div className="w-[400px] max-w-[400px] mx-auto mt-5">
                    <img src={third} alt="about-pic" className="w-[100%]" />
                </div>

            </div>

            <div id="safety" className="flex justify-between flex-wrap align-center items-center mt-[50px]">

            <div className="w-[400px] max-w-[400px] mx-auto mt-5">
                    <img src={fourth} alt="about-pic" className="w-[100%]" />
                </div>
                
                <div className="max-w-[500px] mx-auto">
                    <h1 className="font-semibold text-[30px]">Safety</h1>
                    <p className="mt-5 text-[20px]">1. <span className="text-blue-400">General</span></p>
                    <p>We are highly focused on the security and can solve any issues in short-terms. That’s why we ask you, kindly, to leave a review after purchasing. If you run into any problems with a seller, you can report us and zee Team will check this seller as soon as possible.</p>

                    <p className="mt-5 text-[20px]">2. <span className="text-blue-400">Personal safety tips.</span></p>
                    <ul>
                        <li>- Do not pay in advance, even for the delivery</li>
                        <li>- Try to meet at a safe, public location</li>
                        <li>- Check the item BEFORE you buy it</li>
                        <li>- Pay only after collecting the item</li>
                    </ul>

                    <p className="mt-5 text-[20px]">3. <span className="text-blue-400">Take your item or order a delivery.</span></p>
                    <p>We check our sellers carefully, but it’s always better to check twice, right? Meet a seller in public place and be sure to pay only after collecting your item.</p>

    

    
                </div>

                

            </div>

            {/* <div className="flex justify-between flex-wrap align-center items-center mt-[50px]">

            
                
                <div className="max-w-[500px] mx-auto">
                    <h1 className="font-semibold text-[30px]">Safety</h1>
                    <p className="mt-5 text-[20px]">1. <span className="text-blue-400">General</span></p>
                    <p>We are highly focused on the security and can solve any issues in short-terms. That’s why we ask you, kindly, to leave a review after purchasing. If you run into any problems with a seller, you can report us and zee Team will check this seller as soon as possible.</p>

                    <p className="mt-5 text-[20px]">2. <span className="text-blue-400">Personal safety tips.</span></p>
                    <ul>
                        <li>- Do not pay in advance, even for the delivery</li>
                        <li>- Try to meet at a safe, public location</li>
                        <li>- Check the item BEFORE you buy it</li>
                        <li>- Pay only after collecting the item</li>
                    </ul>

                    <p className="mt-5 text-[20px]">3. <span className="text-blue-400">Take your item or order a delivery.</span></p>
                    <p>We check our sellers carefully, but it’s always better to check twice, right? Meet a seller in public place and be sure to pay only after collecting your item.</p>

                    <p className="mt-5 text-[20px]">4. <span className="text-blue-400">Leave your feedback about the seller.</span></p>
                    <p>Feel free to tell us about your purchase. Your feedback will be published online on the seller’s page and will be very helpful for other buyers. Let’s build a safe and professional business community together!</p>


    
                </div>

                <div className="w-[300px] max-w-[400px] mx-auto mt-5">
                    <img src={fifth} alt="about-pic" className="w-[100%]" />
                </div>

                

            </div> */}
        </main>
    )
}

export default About;