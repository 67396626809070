import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import logo from "../assets/svg/logo4.jpg";
import MobileMenu from "./MobileMenu";
import Notification from "../components/Notification";
// import { collection, addDoc } from "firebase/firestore";
// import { toast } from "react-toastify";
// import { db } from "../firebase";
// import { NotificationIcon } from "./icons";

export default function Header() {
    const [pageState, setPageState] = useState("Sign In");
    const location = useLocation();
    const navigate = useNavigate();

    const pathMatchRoute = (route) => {
        if (route === location.pathname) {
            return true;
        }
    };

    const [showNotificationPopup, setShowNotificationPopup] = useState(false);

    // const sendNotification = async (message) => {
    //     try {
    //         const notificationData = {
    //             userId: auth.currentUser.uid,
    //             message: message,
    //             timestamp: new Date(),
    //         };

    //         await addDoc(collection(db, 'notifications'), notificationData);
    //         toast.success('Notification sent successfully');
    //     } catch (error) {
    //         console.error('Error sending notification:', error);
    //         toast.error('Could not send notification');
    //     }
    // };

    const auth = getAuth();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setPageState("Profile");
            } else {
                setPageState("Sign In");
            }
        });
    }, [auth]);

    return (
        <div className="bg-white border-b shadow-sm sticky top-0 z-40 py-3">
            <header className="flex justify-between items-center px-3 max-w-6xl mx-auto">
                <div>
                    <div className="w-[200px]">
                        <img src={logo} alt="logo" className="cursor-pointer w-[100%]" onClick={() => navigate("/")} />
                    </div>
                    {/* <p className="text-blue-300 text-[16px] p-0 font-medium self-end cursor-pointer" onClick={() => navigate("/")}>Zee Service Provider</p> */}
                </div>
                <div>
                    <ul className="hidden md:flex space-x-10">
                        <li className={`cursor-pointer py-1 text-sm font-semibold text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/")}>Home</li>
                        <li className={`cursor-pointer py-1 text-sm font-semibold text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/about") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/about")}>About Us</li>
                        <li className={`cursor-pointer py-1 text-sm font-semibold text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/hire") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/hire")}>Hire Now</li>
                        <li className={`cursor-pointer py-1 text-sm font-semibold text-black border-b-[3px] border-b-transparent ${pathMatchRoute("/blogs") && "text-black !border-b-[#F6D200]"}`} onClick={() => navigate("/blogs")}>Blogs</li>
                        {/* <li className={`cursor-pointer py-1 text-sm font-semibold text-blue-400 border-b-[3px] border-b-transparent ${pathMatchRoute("/notifications") && "text-black !border-b-[#F6D200]"}`} onClick={() => setShowNotificationPopup(true)}>Notifications</li> */}
                    </ul>
                </div>

                <div className="flex md-down:hidden">
                    <button className={`cursor-pointer px-3 py-2 bg-[#F6D200] text-sm font-semibold text-white rounded-lg ${(pathMatchRoute("/sign-in") || pathMatchRoute("/profile")) && "text-black !border-b-red-500"}`} onClick={() => navigate("/profile")}>
                        {pageState}
                    </button>
                </div>

                <div className='flex md:hidden'>
                    <MobileMenu />
                </div>
            </header>

            {/* Notification Popup */}
            {showNotificationPopup && <Notification onClose={() => setShowNotificationPopup(false)} />}
        </div>
    )
}
